import React, { useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CustomLink from './../common/CustomLink';
import loadable from '@loadable/component';

const ReactSlick = loadable(() => import('../common/ReactSlick'));
const NavDotActive = loadable(() => import('../../images/svg/NavDotActive'));
const ArrowRight = loadable(() => import('../../images/svg/ArrowRight'));
const ArrowLeft = loadable(() => import('../../images/svg/ArrowLeft'));

const Banner = React.memo(({ data }) => {
  const slick = useRef(null);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    arrows: false,
    cssEase: 'ease',
    lazyLoad: false,
    focusOnSelect: false,
    adaptiveHeight: true,
    afterChange: (index) => {
      const current = document.getElementsByClassName('dot active');

      const len = current.length;
      if (len > 0) {
        for (let i = 0; i < len; i++) {
          const _current = current[0];
          _current?.classList?.remove('active');
        }
        const to = document.getElementsByClassName(`dot-${index}`);
        for (let i = 0; i < to.length; i++) {
          const _to = to[i];
          _to?.classList?.add('active');
        }
      }
    },
  };

  function handleSlickGoTo(next = true) {
    if (slick?.current) {
      next ? slick?.current?.slickNext() : slick?.current?.slickPrev();
    }
  }

  function handleSlickGoToIndex(index) {
    slick?.current?.slickGoTo(index);
  }

  return (
    <div id="main-banner" className="container-xl main__banner">
      <ReactSlick ref={slick} {...settings}>
        {data.map((item, i) => (
          <div
            key={i}
            className={`banner ${item.theme}`}
            style={{ backgroundColor: item.background_color }}
          >
            <div className="banner__item">
              <CustomLink className="banner--link-mobile d-md-none" link={item.link} />
              <div className="banner__image image">
                <CustomLink link={item.link}>
                  <GatsbyImage
                    loading={i < 2 ? 'eager' : 'lazy'}
                    image={item.image.gatsbyImageData}
                    alt={item.image.alt || 'activeskin'}
                    fetchpriority="high"
                  />
                </CustomLink>
              </div>
              <div className={`banner__content ${item.theme}`}>
                <div className={`banner__inner`}>
                  <div className="banner__logo">
                    {item?.logo?.gatsbyImageData && (
                      <GatsbyImage
                        image={item.logo.gatsbyImageData}
                        alt={item.logo.alt || 'activeskin'}
                        fetchpriority="high"
                      />
                    )}
                  </div>
                  <div className="banner__text">
                    <h3 className="banner__text--headline">{item.headline}</h3>
                    <p className="banner__text--subheading">{item.subheading}</p>
                    <CustomLink className="banner__text--link" link={item.link}>
                      {item.link_text}
                    </CustomLink>
                  </div>
                </div>

                <div className="banner__dots d-md-block d-none">
                  {data.map((v, i) => (
                    <span
                      className={`dot dot-${i} mr-3 ${i === 0 ? 'active' : ''}`}
                      onClick={() => handleSlickGoToIndex(i)}
                    >
                      <NavDotActive />
                    </span>
                  ))}
                </div>

                <div className="banner__arrows">
                  <span
                    className="banner__arrows--left mr-4"
                    onClick={() => handleSlickGoTo(false)}
                  >
                    <ArrowLeft />
                  </span>
                  <span className="banner__arrows--right" onClick={() => handleSlickGoTo(true)}>
                    <ArrowRight />
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </ReactSlick>
    </div>
  );
});

export default Banner;
